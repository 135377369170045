import { useStaticQuery, graphql } from 'gatsby'

import { useTranslation } from 'react-i18next'

export const usePrograms = () => {
  const { i18n: { language } } = useTranslation()
  const { programsEn, programsRu } = useStaticQuery(
    graphql`
      query {
        programsEn {
          results {
            previewDescription
            description
            id
            image
            goal {
              id
              name
              slug
            }
            prev {
              title
              slug
              id
            }
            next {
              title
              slug
              id
            }
            parts {
              id
              title
              steps {
                description
                title
                image
                id
              }
              description
            }
            previewImage
            slug
            title
          }
        }
        programsRu {
          results {
            previewDescription
            description
            id
            image
            goal {
              id
              name
              slug
            }
            prev {
              title
              slug
              id
            }
            parts {
              id
              title
              steps {
                description
                title
                image
                id
              }
              description
            }
            previewImage
            slug
            title
          }
        }
      }
    `
  )
  if (language === 'en') return programsEn
  return programsRu
}

export default usePrograms
