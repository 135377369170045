import React from "react"

import Layout from "components/Layout/Layout"

import PageProgramDetail from "components/PageProgramDetail/PageProgramDetail"

import usePrograms from "hooks/usePrograms"
import useChangeLanguage from "hooks/useChangeLanguage"

const Programs = ({ pageContext: { slug, lang, articles } }) => {
  useChangeLanguage(lang)
  const programs = usePrograms()
  const program = programs.results.find(item => item.slug === slug)
  return (
    <Layout color="white">
      <PageProgramDetail slug={slug} program={program} articles={articles} />
    </Layout>
  )
}

export default Programs
